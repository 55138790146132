import React, { useState, useEffect } from "react";
import {
  LTUndergroundWire,
  RMU,
  distributionTransformer,
  electricUtility,
  feederBay,
  htCubical,
  htLTlinesTower,
  streetLight2,
  subStation,
  underGroundCable,
} from "../../Assets.tsx";
import "./Carousel.css";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import gsap from "gsap";
import { useGSAP } from "@gsap/react"
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
interface Item {
  image: string;
  name: string;
}

const insects: Item[] = [
  { image: distributionTransformer, name: "Distribution Transformers" },
  { image: feederBay, name: "33KV, 22KV, 11KV Feeder Bay" },
  { image: subStation, name: "33/11KV Substations/Switching stations" },
  {
    image: underGroundCable,
    name: "11KV, 22KV, 33KV Overhead Lines/Underground Cable",
  },
  { image: LTUndergroundWire, name: "LT Overhead Lines/Underground cable" },
  { image: RMU, name: "RMU" },
  { image: htCubical, name: "HT Cubical" },
  { image: streetLight2, name: "Street Light" },
  { image: htLTlinesTower, name: "DTC, HT, LT Line Shifting Project" },
  {
    image: electricUtility,
    name: "NHAI/PWD/MSRDC Electrical Utility Shifting Projects",
  },
  { image: feederBay, name: "33KV, 22KV, 11KV Feeder Bay" },
  { image: subStation, name: "33/11KV Substations/Switching stations" },
];

const Carousel2: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlide2, setCurrentSlide2] = useState(0);
  const [items, setItems] = useState<Item[]>(insects);
  const [items2, setItems2] = useState<Item[]>(insects);

  useGSAP(() => {
    gsap.from(".carousel-container5 h2", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".carousel-container5 h2",
        scroller: "body",
        markers: false,
        start: "top 50%",
      }
    })
  })

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 7000);
    return () => clearInterval(timer);
  }, [currentSlide]);

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide2();
    }, 5000);
    return () => clearInterval(timer);
  }, [currentSlide2]);

  const itemsPerSlide = 4;
  const totalSlides = Math.ceil(items.length / itemsPerSlide);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  const nextSlide2 = () => {
    setCurrentSlide2((prevSlide) => (prevSlide + 1) % totalSlides2);
  };

  const prevSlide2 = () => {
    setCurrentSlide2(
      (prevSlide) => (prevSlide - 1 + totalSlides2) % totalSlides2
    );
  };

  const getSlideItems = () => {
    const startIndex = currentSlide * itemsPerSlide;
    return items.slice(startIndex, startIndex + itemsPerSlide);
  };

  const itemsPerSlide2 = 1;
  const totalSlides2 = Math.ceil(items2.length / itemsPerSlide2);

  const getSlideItems2 = () => {
    const startIndex2 = currentSlide2 * itemsPerSlide2;
    return items2.slice(startIndex2, startIndex2 + itemsPerSlide2);
  };

  const webStyle = {
    CallUsbutton: {
      padding: "5px 8px ",
      fontSize: "14px",
      background: "white",
      marginTop: "15px",
      width: "100%"
    },
    Whatsappbutton: {
      padding: "5px 8px ",
      fontSize: "14px",
      background: "white",
      marginTop: "15px",
      width: "100%"
    },
  };

  const handleContactClick = () => {
    window.location.href = "tel:+91 9420152121";
  };
  const handleWhatsAppClick = () => {
    let phoneNumber = "+919423152121";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };
  return (
    <div id="services" className="carousel-container5">
      <h2
              style={{
                fontSize: "45px",
                textOverflow: "ellipsis",
                letterSpacing: "3px",
                textTransform: "uppercase",
                fontWeight: 300,
                textAlign: "center",
                color: "black",
                marginBottom: "70px",
                fontFamily: "'Cormorant Upright', serif"
              }}
            >
              Our Services
            </h2>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            marginRight: "20px",
          }}
          className="arrowHover"
        >
          <div
            style={{
              cursor: "pointer",
              fontSize: "48px",
              color: "black",
              marginBottom: "5px",
            }}
            className="scroll-arrow"
            id="scroll-left"
            onClick={prevSlide}
          >
            &#8592;
          </div>
        </div>
        
        <div className="carousel5 items">
          {getSlideItems().map((item, index) => (
            <div key={index} className="carousel-item5">
              <img
                src={item.image}
                alt={item.name}
                className="carousel-image5"
              />
              <h5>{item.name}</h5>
              <div className="carousel-overlay">
                <span>{item.name}</span>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p
                    style={{
                      textAlign: "center",
                      margin: "0",
                      marginBottom: "2px",
                    }}
                  >
                    Enquiry Now
                  </p>

                  <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    <button
                      style={webStyle.CallUsbutton}
                      onClick={handleContactClick}
                      className="btn42-44 btn-44"
                    >
                      <CallIcon /> Call Us
                    </button>
                    <button
                      style={webStyle.Whatsappbutton}
                      onClick={handleWhatsAppClick}
                      className="btn42-44 btn-44"
                    >
                      <WhatsAppIcon style={{ color: "green" }} /> Whatsapp Us
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="carousel5 humburger-menu">
          {getSlideItems2().map((item, index) => (
            <div key={index} className="carousel-item5">
              <img
                src={item.image}
                alt={item.name}
                className="carousel-image5"
              />
              <h5 style={{ color: "#1b1b1b", textTransform: "capitalize" }}>
                {item.name}
              </h5>
              <div className="carousel-overlay">
                <span>{item.name}</span>
                <div>
                  <button
                    style={webStyle.CallUsbutton}
                    onClick={handleContactClick}
                  >
                    <CallIcon /> Call Us
                  </button>
                  <button
                    style={webStyle.CallUsbutton}
                    onClick={handleWhatsAppClick}
                  >
                    <WhatsAppIcon style={{ color: "green" }} /> Whatsapp Us
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            marginLeft: "20px",
          }}
          className="arrowHover"
        >
          <div
            style={{
              cursor: "pointer",
              fontSize: "48px",
              color: "black",
              marginBottom: "5px",
            }}
            className="scroll-arrow"
            id="scroll-right"
            onClick={nextSlide}
          >
            &#8594;
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel2;
