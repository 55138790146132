import React from "react";
import "./ProjectCard.css";
import CallIcon from "@mui/icons-material/Call";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

interface CardDetails {
  image: string;
  title: string;
  subtitle?: string;
  description?: string;
}

interface CardProps {
  details: CardDetails[];
}

const ProjectCard: React.FC<CardProps> = ({ details }) => {
  const webStyle = {
    CallUsbutton: {
      padding: "5px 8px ",
      borderRadius: "4px",
      fontSize: "14px",
      background: "white",
      marginTop: "15px",
      width: "100%"
    },
    Whatsappbutton: {
      padding: "5px 8px ",
      borderRadius: "4px",
      fontSize: "14px",
      background: "white",
      marginTop: "15px",
      width: "100%"
    },
  };
  const handleContactClick = () => {
    window.location.href = "tel:+91 9420152121";
  };
  const handleWhatsAppClick = () => {
    let phoneNumber = "+919423152121";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };
  return (
    <div className="cards-container">
      {details.map((detail, index) => (
        <div key={index} className="card">
          <img src={detail.image} alt={detail.title} className="card-image" />
          <div className="card-content" style={{background:"#ead2b2"}}>
            <h3 className="card-title">{detail.title}</h3>
          </div>
          <div className="card-hover-content">
            <h2 style={{ fontSize: "22px" }}>{detail.title}</h2>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <p
                style={{
                  textAlign: "center",
                  margin: "0",
                  marginBottom: "2px",
                }}
              >
                Enquiry Now
              </p>

              <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                <button
                  style={webStyle.CallUsbutton}
                  onClick={handleContactClick}
                  className="btn41-43 btn-43"
                >
                  <CallIcon /> Call Us
                </button>
                <button
                  style={webStyle.Whatsappbutton}
                  onClick={handleWhatsAppClick}
                  className="btn41-43 btn-43"
                >
                  <WhatsAppIcon style={{ color: "green" }} /> Whatsapp Us
                </button>
              </div>
            </div>
            {detail.subtitle && (
              <h3 className="card-subtitle">{detail.subtitle}</h3>
            )}
            {

            }
            <p className="card-description">{detail.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectCard;
