import React, { useEffect, useState, useRef } from "react";
import { Logo, Footerbg } from "../../Assets.tsx";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useHistory } from "react-router-dom";
import "./Footer.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
  const history = useHistory();
  const logoRef = useRef(null);
  const contactRef = useRef(null);
  const emailRef = useRef(null);
  const lightningRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openInNewTab = () => {
    window.open(
      "https://www.google.com/maps/place/Harshnil+Powercon/@18.4981417,73.9611032,17z/data=!3m1!4b1!4m6!3m5!1s0x3bc2c3006489355b:0xf84c42cca3a40628!8m2!3d18.4981417!4d73.9611032!16s%2Fg%2F11y7cy0vw8?entry=ttu",
      "_blank",
      "noopener,noreferrer"
    );
  };

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".FooterSection", {
        y: -30,
        opacity: 0,
        duration: 1,
        stagger: 0.5,
        delay: 1,
        scrollTrigger: {
          trigger: ".FooterSection",
          scroller: "body",
          markers: false,
          start: "top 80%",
        },
      });
      gsap.from(".gsapFooterLogo", {
        opacity: 0,
        duration: 1,
        scale: 0.5,
        y: 20,
        delay: 1,
        scrollTrigger: {
          trigger: ".gsapFooterLogo",
          scroller: "body",
          markers: false,
          start: "top 65%",
        },
      });
    }
  });

  const handleLinkClick = (path: string) => {
    history.push(path);
  };

  const handleContactClick = () => {
    window.location.href = "tel:9423152121";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:harshnil97@gmail.com";
  };

  const handleWhatsAppClick = () => {
    window.open("https://wa.me/919423152121", "_blank");
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        background: `url(${Footerbg}) no-repeat center center/cover`,
        color: "black",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        ref={lightningRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background:
            "radial-gradient(circle, rgba(255,255,255,0.1) 20%, rgba(255,255,255,0) 40%)",
          opacity: 0,
          mixBlendMode: "overlay",
          pointerEvents: "none",
          zIndex: 1,
        }}
      ></div>
      <div style={{ position: "relative", width: "216px", height: "120px" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            overflow: "hidden",
            zIndex: 1,
          }}
          className="gsapFooterLogo"
        >
          <iframe
            src="https://giphy.com/embed/l0HlLP5fowu9vAbUk"
            width="80px"
            height="120px"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              marginLeft: "63px",
              paddingBottom: "37px",
            }}
            frameBorder="0"
            className="giphy-embed"
            allowFullScreen
          ></iframe>
        </div>
        <img
          ref={logoRef}
          src={Logo}
          alt="icon"
          style={{
            width: "216px",
            height: "120px",
            cursor: "pointer",
            position: "relative",
            zIndex: 2,
          }}
          className="lightning-effect gsapFooterLogo"
          onClick={() => {
            history.push("/");
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          maxWidth: "1200px",
          marginTop: "80px",
          position: "relative",
          zIndex: 2,
        }}
        className="FooterContainer"
      >
        <div
          ref={contactRef}
          style={{
            display: "flex",
            flexDirection: "column",
            textTransform: "capitalize",
            lineHeight: 1.5,
          }}
          className="FooterSection"
        >
          <span style={{ fontSize: "21px", fontWeight: 800 }}>SERVICES</span>
          <span
            style={{ fontSize: "20px", color: "black", fontWeight: 600 }}
            className="RouteOptions2"
          >
            Call Us
          </span>
          <span
            onClick={handleContactClick}
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
            }}
          >
            +91 9423152121 ,
          </span>
          <span
            onClick={handleContactClick}
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
            }}
          >
            +91 9420152121
          </span>
          <span
            style={{ fontSize: "20px", color: "black", fontWeight: 600 }}
            className="RouteOptions2"
          >
            Whatsapp Us
          </span>
          <span
            onClick={handleWhatsAppClick}
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
            }}
          >
            +91 9423152121
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textTransform: "capitalize",
            lineHeight: 1.5,
          }}
          className="FooterSection"
        >
          <span style={{ fontSize: "21px", fontWeight: 800 }}>
            EXPLORE MORE
          </span>
          <a
            href="#projects"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span
              style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
              className="RouteOptions2"
            >
              Projects
            </span>
          </a>
          <a
            href="#about-us"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span
              style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
              className="RouteOptions2"
            >
              About Us
            </span>
          </a>
          <a
            href="#services"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span
              style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
              className="RouteOptions2"
            >
              Services
            </span>
          </a>
          <a
            href="#clients"
            onClick={() => handleLinkClick("/")}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span
              style={{ fontSize: "20px", color: "black", cursor: "pointer" }}
              className="RouteOptions2"
            >
              Clients
            </span>
          </a>
        </div>
        <div
          ref={emailRef}
          style={{
            display: "flex",
            flexDirection: "column",
            textTransform: "capitalize",
            lineHeight: 1.5,
          }}
          className="FooterSection"
        >
          <span style={{ fontSize: "21px", fontWeight: 800, color: "black" }}>
            FOLLOW US ON
          </span>
          <div>
            <FacebookIcon
              style={{
                color: "black",
                marginRight: "10px",
                cursor: "pointer",
              }}
            />
            <InstagramIcon
              style={{
                color: "black",
                marginRight: "10px",
                cursor: "pointer",
              }}
            />
          </div>
          <span
            style={{
              fontSize: "21px",
              fontWeight: 800,
              marginBottom: "1px",
              textTransform: "capitalize",
              marginTop: "12px",
            }}
          >
            Mail
          </span>
          <span
            onClick={handleEmailClick}
            style={{
              fontSize: "20px",
              color: "black",
              cursor: "pointer",
              textTransform: "none",
            }}
          >
            harshnil97@gmail.com
          </span>
          <div className="map-container">
            <div className="map-overlay" onClick={openInNewTab}></div>
            <iframe
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAAdnoL9ZLaHUAZQ0z1QDjgQWXJCkFlCuE&q=Harshnil+Powercon,+Pune,+Maharashtra"
              width="100%"
              height="150px"
              style={{ border: 0, borderRadius: "5px" }}
              loading="lazy"
              title="Google Map"
              className="googleMap"
            ></iframe>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          className="FooterSection"
        >
          <span
            style={{
              fontSize: "21px",
              fontWeight: 800,
              marginBottom: "10px",
              textTransform: "capitalize",
            }}
          >
            KeyPersons
          </span>
          <span
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "black",
            }}
          >
            Anil A Kharade
          </span>
          <span
            style={{
              fontSize: "20px",
              marginBottom: "10px",
              color: "black",
            }}
          >
            (Project Head- Electrical)
          </span>
          <span
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: "black",
            }}
          >
            Harsha A Kharade
          </span>
          <span
            style={{
              fontSize: "20px",
              marginBottom: "9px",
              color: "black",
            }}
          >
            (Project coordinator)
          </span>
        </div>
      </div>

      <h6
        style={{
          textAlign: "center",
          position: "relative",
          top: "100px",
          color: "gray",
          fontWeight: 200,
          fontSize: "14px",
        }}
      >
        2024 © Harshnil Powercon Pvt. Ltd. All rights reserved.
      </h6>
    </div>
  );
};

export default Footer;
