import React from "react";
import { Carousel } from "react-bootstrap";
import { HdBulb2, HdBulb, HdTower, streetLight, wire } from "../../Assets.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const BannerCarousel = () => {
  useGSAP(() => {
    gsap.from(".BannerTittleGsap", {
      y: 50,
      opacity: 0,
      duration: 1,
      delay: 1,
    });
  });

  return (
    <Carousel fade={true} interval={3000}>
      <Carousel.Item
        style={{ height: "720px" }}
        className="BannerImageCarousel"
      >
        <img
          style={{ height: "720px" }}
          className="d-block w-100"
          src={HdTower}
          alt="first slide"
        />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
          className="gsapBannerImageCarousel"
        >
          <div
            className="BannerTittleGsap"
            style={{
              fontSize: "40px",
              fontWeight: "bold",
              textAlign: "center",
              position: "relative",
              bottom: "0px",
              left: "170px",
              width: "77%",
              fontFamily: "'Cormorant Upright', serif",
            }}
          >
            Harshnil Powercon is engage in the business of undertaking turnkey,
            patialytunkey contracts for Supply, erection and commissioning of
            electrical installations in power distribution sector under
            Maharshtra.
          </div>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item
        style={{ height: "720px" }}
        className="BannerImageCarousel"
      >
        <img
          style={{ height: "720px" }}
          className="d-block w-100"
          src={HdBulb}
          alt="second slide"
        />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
          className="gsapBannerImageCarousel"
        >
          <div
            className="BannerTittleGsap"
            style={{
              fontSize: "40px",
              fontWeight: "bold",
              textAlign: "center",
              position: "relative",
              bottom: "0px",
              left: "170px",
              width: "77%",
              fontFamily: "'Cormorant Upright', serif",
            }}
          >
            Harshnil Powercon is engage in the business of undertaking turnkey,
            patialytunkey contracts for Supply, erection and commissioning of
            electrical installations in power distribution sector under
            Maharshtra.
          </div>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item
        style={{ height: "720px" }}
        className="BannerImageCarousel"
      >
        <img
          style={{ height: "720px" }}
          className="d-block w-100"
          src={wire}
          alt="fifth slide"
        />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
        >
          <div
            className="BannerTittleGsap"
            style={{
              fontSize: "40px",
              fontWeight: "bold",
              textAlign: "center",
              position: "relative",
              bottom: "0px",
              left: "170px",
              width: "77%",
              fontFamily: "'Cormorant Upright', serif",
            }}
          >
            Harshnil Powercon is engage in the business of undertaking turnkey,
            patialytunkey contracts for Supply, erection and commissioning of
            electrical installations in power distribution sector under
            Maharshtra.
          </div>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item
        style={{ height: "720px" }}
        className="BannerImageCarousel"
      >
        <img
          style={{ height: "720px" }}
          className="d-block w-100"
          src={streetLight}
          alt="sixth slide"
        />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
        >
          <div
            className="BannerTittleGsap"
            style={{
              fontSize: "40px",
              fontWeight: "bold",
              textAlign: "center",
              position: "relative",
              bottom: "0px",
              left: "170px",
              width: "77%",
              fontFamily: "'Cormorant Upright', serif",
            }}
          >
            Harshnil Powercon is engage in the business of undertaking turnkey,
            patialytunkey contracts for Supply, erection and commissioning of
            electrical installations in power distribution sector under
            Maharshtra.
          </div>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item
        style={{ height: "720px" }}
        className="BannerImageCarousel"
      >
        <img
          style={{ height: "720px" }}
          className="d-block w-100"
          src={HdBulb2}
          alt="Third slide"
        />
        <Carousel.Caption
          style={{
            position: "absolute",
            bottom: "20px",
            left: 0,
            right: 0,
            textAlign: "center",
            color: "white",
          }}
        >
          <div
            className="BannerTittleGsap"
            style={{
              fontSize: "40px",
              fontWeight: "bold",
              textAlign: "center",
              position: "relative",
              bottom: "0px",
              left: "170px",
              width: "77%",
              fontFamily: "'Cormorant Upright', serif",
            }}
          >
            Harshnil Powercon is engage in the business of undertaking turnkey,
            patialytunkey contracts for Supply, erection and commissioning of
            electrical installations in power distribution sector under
            Maharshtra.
          </div>
          <p></p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default BannerCarousel;
