export const Logo = require("../src/Assets/Logo.png");
export const Footerbg = require("../src/Assets/Footer_bg.jpeg");
export const bulb1 = require("../src/Assets/bulb1.jpg");
export const bulb2 = require("../src/Assets/bulb2.jpg");
export const bulb3 = require("../src/Assets/bulb3.jpg");
export const bulb4 = require("../src/Assets/bulb4.jpg");
export const electricTowers = require("../src/Assets/electric-towers.jpg");
export const electricTowersBg1 = require("../src/Assets/tower-bg1.jpg");
export const transformer = require("../src/Assets/transformer.jpg");
export const transformerBg1 = require("../src/Assets/transformer-bg.jpg");
export const greenBulb = require("../src/Assets/green-bulb.jpg");
export const streetLight = require("../src/Assets/street-light.jpg");
export const wire = require("../src/Assets/wires.jpg");
export const subStation = require("../src/Assets/substation.jpg");
export const feederBay = require("../src/Assets/feeder-bay.jpg");
export const underGroundCable = require("../src/Assets/underground-cable.jpg");
export const distributionTransformer = require("../src/Assets/distribution-transformer.jpg");
export const streetLight2 = require("../src/Assets/street-light2.webp");
export const LTUndergroundWire = require("../src/Assets/LT-underground-wire.jpg");
export const RMU = require("../src/Assets/RMU.jpg");
export const HTLT = require("../src/Assets/ht-lt-lines-towers.jpg");
export const htCubical = require("../src/Assets/ht-cubical.webp");
export const htLTlinesTower = require("../src/Assets/ht-lt-lines-towers.jpg");
export const electricUtility = require("../src/Assets/electrical-utility.webp");
export const ashokaLogo = require("../src/Assets/ashoka logo.png");
export const ncc = require("../src/Assets/ncc.jpeg");
export const tT = require("../src/Assets/T&T.png");
export const Grace = require("../src/Assets/allGrace.jpg");
export const bharat = require("../src/Assets/bharat.jpeg");
export const zauba = require("../src/Assets/zauba.png");
export const sunway = require("../src/Assets/sunway.png");
export const mahavitran = require("../src/Assets/mahavitran.jpg");
export const adani = require("../src/Assets/adani.png");
export const np = require("../src/Assets/np.jpeg");
export const anish = require("../src/Assets/anish.png");
export const allGrace = require("../src/Assets/all grace.jpeg");
export const DevYash = require("../src/Assets/devyansh.png");
export const HdBulb = require("../src/Assets/hd-bulb.jpg");
export const HdBulb2 = require("../src/Assets/hd-bulb2.jpg");
export const HdTower = require("../src/Assets/hd-tower.jpg");


