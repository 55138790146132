import React, { useEffect, useState } from "react";
import Navbar from "./Navbar/Navbar.tsx";
import BannerCarousel from "./Common-components/BannerCarousel.tsx";
import Footer from "./Footer/Footer.tsx";
import ProductsCarousel from "./Common-components/ProductsCarousel.tsx";
import {
  bulb1,
  bulb2,
  bulb3,
  distributionTransformer,
  feederBay,
  HdBulb,
  HdBulb2,
  htCubical,
  LTUndergroundWire,
  RMU,
  streetLight2,
  subStation,
  underGroundCable,
} from "../Assets.tsx";
import { Box, Tab, Tabs } from "@mui/material";
import ClientsCarousel from "./Common-components/ClientsCarousel.tsx";
import ProjectCard from "./Common-components/ProjectCard.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "2% 0px",
      }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const profile = "/profile.pdf";

const openPDF = (link: string) => {
  window.open(link, "_blank");
};

const HomePage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useGSAP(() => {
    if (!isMobile) {
    gsap.from(".aboutTitleTextGsap h2", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".aboutTitleTextGsap h2",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
    gsap.from(".meeting-events-img", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".meeting-events-img",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
    gsap.from(".projectGsap h1", {
      opacity: 0,
      duration: 2,
      x: 500,
      scrollTrigger: {
        trigger: ".projectGsap h1",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
    gsap.from(".clientSectionGsap h1", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".clientSectionGsap h1",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
    gsap.from(".meeting-events-text", {
      opacity: 0,
      duration: 2,
      x: 500,
      scrollTrigger: {
        trigger: ".meeting-events-text",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
    gsap.from(".gsapOurMission", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".gsapOurMission",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
    gsap.from(".gsapOurPromise", {
      opacity: 0,
      duration: 2,
      x: 500,
      scrollTrigger: {
        trigger: ".gsapOurPromise",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
    gsap.from(".gsapOurVision", {
      opacity: 0,
      duration: 1,
      scale: 0.3,
      y: 20,
      delay: 1,
      scrollTrigger: {
        trigger: ".gsapOurVision",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
    gsap.from(".gsapOurMissionImg", {
      opacity: 0,
      duration: 2,
      x: 500,
      scrollTrigger: {
        trigger: ".gsapOurMissionImg",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
    gsap.from(".our-mission-section", {
      opacity: 0,
      duration: 2,
      x: -500,
      scrollTrigger: {
        trigger: ".our-mission-section",
        scroller: "body",
        markers: false,
        start: "top 50%",
      },
    });
}});

  const ProjectDetails = [
    { image: distributionTransformer, title: "Distribution Transformers" },
    { image: feederBay, title: "33KV, 22KV, 11KV Feeder Bay" },
    { image: subStation, title: "33/11KV Substations/Switching stations" },
    {
      image: underGroundCable,
      title: "11KV, 22KV, 33KV Overhead Lines/Underground Cable",
    },
    { image: LTUndergroundWire, title: "LT Overhead Lines/Underground cable" },
    { image: RMU, title: "RMU" },
    { image: htCubical, title: "HT Cubical" },
    { image: streetLight2, title: "Street Light" },
  ];
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div>
      <Navbar />
      {/* Banner-section */}
      <div id="banner-carousel">
        <div id="hero-carousel">
          <BannerCarousel />
        </div>
      </div>
      {/* Service Section */}
      <div id="service-section">
        <div className="services">
          <ProductsCarousel />
        </div>
      </div>
      {/* About Section */}
      <div id="about-section">
        <div id="about-us">
          <div
            className="aboutTitleTextGsap"
            id="meeting&events"
            style={{ marginBottom: "40px" }}
          >
            <h2
              style={{
                fontSize: "45px",
                textOverflow: "ellipsis",
                letterSpacing: "3px",
                textTransform: "uppercase",
                fontWeight: 300,
                textAlign: "center",
                color: "black",
                fontFamily: "'Cormorant Upright', serif"
              }}
            >
              About Us
            </h2>
          </div>
          <div className="meeting-events-section">
            <div className="meeting-events-text">
              <h3 className="meeting-events-heading">
                Introduction of Organization{" "}
              </h3>
              <div style={{textAlign: "justify"}}>
                <b>Harshnil Powercon</b>, a company incorporated on
                21thJanuary2016 by MrAnil A Kharade. The head office is in Pune
                & Site office is in Karjat, DistAhmednagar.Mr.Anil completed
                BE(Electrical) in 2009& Take 7Yrs Experience in Power
                distribution project. Then He established his proprietary
                concern in the name of HarshnilPowercon. This organization has
                been in business for over the past 4 years.
                <br />
                <b>Harshnil Powercon</b> is engage in the business of
                undertaking turnkey, patialytunkey contracts for Supply,
                erection and commissioning of electrical installations in power
                distribution sector under Maharshtra.
              </div>
              <button
                onClick={() => openPDF(profile)}
                className="btn41-43 btn-43"
              >
                Company Profile
              </button>
            </div>
            <img src={HdBulb} alt="icon" className="meeting-events-img" />
          </div>
        </div>
      </div>
      {/* Our Project Section */}
      <div id="projects" className="projectGsap">
        <h1
          style={{
            textAlign: "center",
            fontSize: "45px",
            textOverflow: "ellipsis",
            letterSpacing: "3px",
            textTransform: "uppercase",
            fontWeight: 300,
            color: "black",
            marginTop: "50px",
            fontFamily: "'Cormorant Upright', serif"
          }}
        >
          Projects
        </h1>
        <ProjectCard details={ProjectDetails} />
      </div>

      {/* Our Client Section */}
      <div
        style={{
          width: "100%",
          height: "700px",
          background: "lightgray",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
        className="clientSectionGsap"
        id="clients"
      >
        <h1
          style={{
            textAlign: "center",
            marginTop: "50px",
            fontSize: "45px",
            textOverflow: "ellipsis",
            letterSpacing: "3px",
            textTransform: "uppercase",
            fontWeight: 300,
            color: "black",
            fontFamily: "'Cormorant Upright', serif"
          }}
        >
          Our Reputed Clients
        </h1>
        <ClientsCarousel />
      </div>
      {/* About-Us Tabs Section */}
      <div id="about-section-tabs">
        <div id="about-us-tabs">
          <Box className="fixed-bg2">
            <Box
              sx={{
                width: "100%",
                padding: "3%",
              }}
              className="fixed-bg-content2"
            >
              <Box
                sx={{ color: "white", border: "none", textDecoration: "none" }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  centered
                  sx={{
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                    "& .MuiTab-root": {
                      backgroundColor: "white",
                      color: "black",
                      border: "1px solid black",
                      margin: "0 1%",
                      width: "200px",
                      fontSize: "18px",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#692427",
                      color: "white !important",
                      border: "none !important",
                    },
                  }}
                >
                  <Tab
                    className="coolBeans gsapOurMission"
                    label="Our Mission"
                    {...a11yProps(0)}
                    style={{fontFamily: "'Cormorant Upright', serif", fontWeight: 700}}
                  />
                  <Tab
                    className="coolBeans gsapOurVision ourVisionButton"
                    label="Our Vision"
                    {...a11yProps(1)}
                    style={{fontFamily: "'Cormorant Upright', serif", fontWeight: 700}}
                  />
                  <Tab
                    className="coolBeans gsapOurPromise"
                    label="Our Promise"
                    {...a11yProps(2)}
                    style={{fontFamily: "'Cormorant Upright', serif", fontWeight: 700}}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                    gap: "6%",
                  }}
                  className="our-mission"
                >
                  <div
                    style={{
                      color: "black",
                      width: "50%",
                      textAlign: "justify",
                      fontSize: "16px",
                    }}
                    className="our-mission-section"
                  >
                    At <b>Harshil Powercon</b>, our mission is to be the premier
                    provider of electrical solutions, delivering outstanding
                    service and unmatched quality in every project we undertake.
                    We are dedicated to fostering long-term relationships with
                    our clients by offering reliable, innovative, and
                    cost-effective electrical services. Our mission is driven by
                    our commitment to:
                    <ul>
                      <li>
                        <b>Safety:</b> Ensuring the highest standards of safety
                        for our clients, team, and the environment.
                      </li>
                      <li>
                        <b>Quality:</b> Providing superior craftsmanship and
                        attention to detail in every project.
                      </li>
                      <li>
                        <b>Customer Focus:</b> Tailoring our services to meet
                        the unique needs of each client with professionalism and
                        integrity.
                      </li>
                      <li>
                        <b>Innovation:</b> Utilizing the latest technology and
                        industry best practices to enhance efficiency and
                        effectiveness. Our goal is to transform our clients'
                        visions into reality through dependable and expert
                        electrical solutions, setting a benchmark for excellence
                        in the industry.
                      </li>
                    </ul>
                  </div>
                  <div className="gsapOurMissionImg">
                    <img
                      src={bulb1}
                      alt="icon"
                      className="aboutUsImage"
                      style={{
                        height: "445px",
                        width: "606px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                    gap: "6%",
                  }}
                  className="our-mission"
                >
                  <div
                    style={{
                      color: "black",
                      width: "50%",
                      textAlign: "justify",
                      fontSize: "16px",
                    }}
                    className="our-mission-section"
                  >
                    At <b>Harshil Powercon</b>, Our vision is to be recognized
                    as the leading electrical contractor in India, renowned for
                    our expertise, integrity, and commitment to excellence. We
                    aspire to:
                    <ul>
                      <li>
                        <b>Lead by Example:</b> Set new standards for quality,
                        safety, and customer service in the electrical
                        contracting industry.
                      </li>
                      <li>
                        <b>Innovate Continuously:</b> Embrace and implement the
                        latest technologies and techniques to stay ahead in a
                        rapidly evolving field.
                      </li>
                      <li>
                        <b>Sustainably Contribute:</b> Promote and implement
                        environmentally friendly practices that support a
                        sustainable future.
                      </li>
                      <li>
                        <b>Build Trust:</b> Cultivate strong, long-lasting
                        relationships with clients, partners, and our community
                        through transparency, reliability, and superior service.
                        By envisioning a future where our company is synonymous
                        with excellence and innovation, we aim to influence the
                        industry positively and contribute to a brighter, more
                        efficient tomorrow.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <img
                      src={HdBulb2}
                      alt="icon"
                      className="aboutUsImage"
                      style={{
                        height: "445px",
                        width: "606px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "left",
                    gap: "6%",
                  }}
                  className="our-mission"
                >
                  <div
                    style={{
                      color: "black",
                      width: "50%",
                      textAlign: "justify",
                      fontSize: "16px",
                    }}
                    className="our-mission-section"
                  >
                    <ol>
                      <li>
                        <b>Exceptional Quality:</b> We promise to deliver
                        superior craftsmanship and attention to detail on every
                        project. Our work meets and exceeds industry standards,
                        ensuring long-lasting and reliable results.
                      </li>
                      <li>
                        <b>Uncompromised Safety:</b> Safety is our top priority.
                        We adhere to rigorous safety protocols and standards to
                        protect our team, clients, and property. Our commitment
                        to a safe working environment is unwavering.
                      </li>
                      <li>
                        <b>Transparent Communication:</b> We pledge to maintain
                        clear and open communication throughout the entire
                        project. From initial consultation to project
                        completion, we keep you informed and involved in every
                        step of the process.
                      </li>
                      <li>
                        <b>Timely Completion:</b> We understand the importance
                        of deadlines. Our team is dedicated to completing
                        projects on time and within budget, without compromising
                        on quality or safety.
                      </li>
                      <li>
                        <b>Customer Satisfaction:</b> Your satisfaction is our
                        success. We are committed to addressing your needs and
                        concerns promptly and effectively, ensuring a positive
                        experience from start to finish.
                      </li>
                      <li>
                        <b>Innovative Solutions:</b> We promise to leverage the
                        latest technology and industry advancements to provide
                        innovative and efficient electrical solutions that meet
                        the evolving needs of our clients.
                      </li>
                    </ol>
                  </div>
                  <div>
                    <img
                      src={bulb3}
                      alt="icon"
                      className="aboutUsImage"
                      style={{
                        height: "445px",
                        width: "606px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </div>
              </CustomTabPanel>
            </Box>
          </Box>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
